.jatek{
  background: $lila url('/images/fooldal_03.jpg') 50% 0 no-repeat;
  background-size: cover;
  padding: 30px 20px 25px;
  min-height: 680px;

  h2{
    margin-top: 50px;
  }


  form{
    margin-top: 20px;
    margin-bottom: 50px;

    input[type=radio]{
      display: none;
    }

    .radio{
      .btn{
        margin-top: 15px;
        padding: 10px 50px;
        width: 300px;
      }
    }


    >.btn-primary{
      margin-top: 50px;
    }
  }
}