@import "_bootstrap.scss";
@import "_fooldal.scss";
@import "_jatek.scss";
@import "_vege.scss";


body{
  background: #e9ebee;
}

h1, h2, h3 {
  color: $sotetlila;
}

body > .content{
  padding: 0 !important;
}

.btn {
  text-transform: uppercase;
  padding: 15px 20px;
  min-width: 140px;
}

.btn-kek {
  color: white;
  background: $kek;
  border: darken($kek, 10%);
  opacity: 0.9;

  &:hover,&:focus{
    color: white;
    background: $lila;
    opacity: 1;
  }
}
