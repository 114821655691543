.vege{
  background: $lila url('/images/fooldal_03.jpg') 50% 0 no-repeat;
  background-size: cover;
  padding: 30px 20px 25px;
  min-height: 680px;

  h2{
    margin-top: 50px;
  }


  form{
    margin-top: 20px;
    margin-bottom: 50px;

    input[type=radio]{
      display: none;
    }

    .radio{
      .btn{
        margin-top: 15px;
        padding: 10px 50px;
        width: 300px;
      }
    }

    .form-control{
      max-width: 300px;
      margin: 0 auto;

      &:focus{
        border-color: $narancs;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(red($narancs),green($narancs),blue($narancs), 0.6)
      }
    }

    input[type=text]{
      color: $sotetlila;
      background: rgba(red($sarga),green($sarga),blue($sarga),0.9);
    }


    >.btn-primary{
      margin-top: 50px;
    }
  }
}