.fooldal{
  background: $lila url('/images/fooldal_03.jpg') 50% 0 no-repeat;
  background-size: cover;
  padding: 30px 20px 25px;

  .lead{
    margin: 20px 5px 50px;
    font-size: 20px;
  }

  .btn{
    margin-bottom: 60px;
    font-size: 20px;
  }
}